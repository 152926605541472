import { isBrowser } from 'react-device-detect';

import { State } from '..';
import Spacing from '../../../component/Spacing';
import Text from '../../../component/Text';
import c from '../../../util/c';
import ImagesContainer from './ImagesContainer';

const INPUT_CLASSNAME = c(
  'rounded-[4px]',
  'h-[44px] w-full',
  'placeholder:font-Pretendard-Regular placeholder:text-[16px] placeholder:leading-[30px] placeholder:text-Gray/70',
  'font-Pretendard-Regular text-[16px] leading-[30px] text-black',
  'focus:outline-none',
);

export type ModalProgress = 'close' | 'open' | 'submit';

export type ModalState = {
  progress: ModalProgress;
  setProgress: React.Dispatch<React.SetStateAction<ModalProgress>>;
};

const ConfirmModal = (props: { state: State; modalState: ModalState }) => {
  if (props.modalState.progress === 'close') return undefined;

  return (
    <div
      className={c(
        'absolute left-1/2 top-1/2 z-10 h-full w-full rounded bg-white px-[16px]',
        '-translate-x-[50%] -translate-y-[50%]',
      )}
    >
      <div className='scrollbar-hidden relative overflow-y-auto'>
        <Spacing height='h-[44px]' />
        <div className={c('py-[12px]', 'flex justify-center')}>
          <Text
            size={20}
            lineHeight='leading-[20px]'
            color='text-[#000000]'
            weight='Bold'
          >
            접수 확인
          </Text>
        </div>
        <Spacing height='h-[10px]' />
        <div className={c('flex items-center justify-between')}>
          <div>
            <Text
              size={13}
              weight='SemiBold'
              color='text-[#000000]'
              lineHeight='leading-[30px]'
            >
              병원명{' '}
            </Text>
          </div>
        </div>
        <div className={c('relative', 'h-[44px] w-full')}>
          <p className={c(INPUT_CLASSNAME, '!h-full')}>{props.state.hospitalName}</p>
        </div>
        <div>
          <Text
            size={13}
            weight='SemiBold'
            color='text-[#000000]'
            lineHeight='leading-[30px]'
          >
            환자 고유번호{' '}
          </Text>
        </div>
        <div className={c('flex items-center space-x-[9px]')}>
          <p className={INPUT_CLASSNAME}>{props.state.patientId}</p>
        </div>
        <div>
          <Text
            size={13}
            weight='SemiBold'
            color='text-[#000000]'
            lineHeight='leading-[30px]'
          >
            환자 이름{' '}
          </Text>
        </div>
        <p className={c(INPUT_CLASSNAME)}>{props.state.patientName}</p>
        <Text
          size={13}
          weight='SemiBold'
          color='text-[#000000]'
          lineHeight='leading-[30px]'
        >
          환자 생년월일
        </Text>
        <p className={INPUT_CLASSNAME}>{props.state.birthDate}</p>
        <Text
          size={13}
          weight='SemiBold'
          color='text-[#000000]'
          lineHeight='leading-[30px]'
        >
          접수 일자
        </Text>
        <p className={INPUT_CLASSNAME}>{props.state.submitDate}</p>
        <div>
          <Text
            size={13}
            weight='SemiBold'
            color='text-[#000000]'
            lineHeight='leading-[30px]'
          >
            환부사진{' '}
          </Text>
        </div>
        <ImagesContainer photos={props.state.photos} />
      </div>
      <Spacing height='h-[20px]' />
      <div
        className={c(
          'absolute bottom-0 left-1/2 flex -translate-x-[50%] gap-4',
          'px-[16px] pb-[20px]',
          isBrowser ? 'w-[375px]' : 'w-full',
        )}
      >
        <button
          onClick={() => props.modalState.setProgress('close')}
          className={c(
            'h-[54px] w-full',
            'flex items-center justify-center',
            'font-Pretendard-SemiBold text-[16px] leading-[30px] text-[#343434]',
            'rounded-[8px] bg-Gray',
          )}
        >
          취소
        </button>
        <button
          onClick={() => props.modalState.setProgress('submit')}
          className={c(
            'h-[54px] w-full',
            'flex items-center justify-center',
            'font-Pretendard-SemiBold text-[16px] leading-[30px] text-white',
            'rounded-[8px] bg-blue_main_2',
          )}
        >
          접수
        </button>
      </div>
    </div>
  );
};

export default ConfirmModal;
