import { useRef, useState } from 'react';

import { FORM_ERROR } from '..';
import Spacing from '../../../component/Spacing';
import Text from '../../../component/Text';
import api from '../../../repository';
import c from '../../../util/c';

type UploadImagesContainerProps = {
  photos: Array<{
    id: string;
    preview: string;
    fileName: string;
  }>;
  handleAddPhotos: (files: Array<{ blob: Blob; fileName: string }>) => void;
  handleRemovePhoto: (id: string) => void;
  handleValidateMessage: (message: FORM_ERROR) => void;
};

const UploadImagesContainer = ({
  photos,
  handleAddPhotos,
  handleRemovePhoto,
  handleValidateMessage,
}: UploadImagesContainerProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleButtonClick = () => fileInputRef.current && fileInputRef.current.click();

  const resizeFile = (worker: Worker, file: File) => {
    return new Promise((resolve, reject) => {
      worker.onmessage = (event) => {
        const { success, resizedBlob, error } = event.data;
        if (success) {
          resolve(resizedBlob);
        } else {
          reject(error);
        }
      };

      worker.postMessage({ file, maxWidth: 867, maxHeight: 500 });
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles || selectedFiles.length === 0) return;
    if (photos.length + selectedFiles.length > 5) {
      handleValidateMessage(FORM_ERROR.AL_002);
      return;
    }

    const worker = new Worker(new URL('./resizeWorker.js', import.meta.url)); // Worker 하나만 생성
    const resizedFiles = [];

    for (const file of selectedFiles) {
      const resizedBlob = await resizeFile(worker, file);
      resizedFiles.push({ blob: resizedBlob as Blob, type: file.type });
    }

    worker.terminate(); // 작업 완료 후 Worker 종료

    Promise.all(
      resizedFiles.map(async ({ blob, type }) => {
        const {
          data: { signedUrl, fileName },
        } = await api.v1.users.affectedArea.presignedUrl.POST();
        const resizedFile = new File([blob], fileName, { type });
        const { ok } = await fetch(signedUrl, {
          method: 'PUT',
          headers: { 'Content-Type': resizedFile.type },
          body: blob, // 실제 파일 내용
        });
        if (ok) handleAddPhotos([{ blob, fileName }]);
      }),
    );
    event.target.value = '';
  };

  const scrollableRef = useRef<HTMLUListElement>(null); // 스크롤 가능한 영역 참조
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState({ left: 0, top: 0 });

  const handleMouseDown = (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    const scrollable = scrollableRef.current;
    if (!scrollable) return;

    setIsDragging(true);
    setStartPosition({
      x: e.pageX - scrollable.offsetLeft,
      y: e.pageY - scrollable.offsetTop,
    });
    setScrollPosition({
      left: scrollable.scrollLeft,
      top: scrollable.scrollTop,
    });
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    const scrollable = scrollableRef.current;
    if (!scrollable || !isDragging) return;

    e.preventDefault();
    const x = e.pageX - scrollable.offsetLeft;
    const y = e.pageY - scrollable.offsetTop;

    const walkX = (x - startPosition.x) * 1; // x축 이동 거리
    const walkY = (y - startPosition.y) * 1; // y축 이동 거리

    scrollable.scrollLeft = scrollPosition.left - walkX;
    scrollable.scrollTop = scrollPosition.top - walkY;
  };

  const handleMouseUpOrLeave = () => setIsDragging(false);

  return (
    <>
      <input
        multiple
        type='file'
        accept='image/*'
        ref={fileInputRef}
        className='hidden'
        onChange={(e) => handleFileChange(e)}
      />
      <ul
        ref={scrollableRef}
        className={c(
          'flex items-center justify-start',
          'space-x-[10px]',
          'h-[84px]',
          'scrollbar-hidden overflow-x-auto',
          isDragging && 'cursor-grabbing',
        )}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUpOrLeave}
        onMouseLeave={handleMouseUpOrLeave}
      >
        <button
          type='button'
          onClick={handleButtonClick}
          className={c(
            'rounded-[4px]',
            'min-h-[84px] min-w-[84px]',
            'bg-[#F2F4F5]',
            'flex flex-col items-center',
          )}
        >
          <Spacing height='h-[18px]' />
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M6 12H12M12 12H18M12 12V18M12 12V6'
              stroke='#848484'
            />
          </svg>
          <Text
            size={16}
            weight='Medium'
            color='text-Gray/70'
            lineHeight='leading-[30px]'
          >
            사진 선택
          </Text>
        </button>
        {photos.map((file) => (
          <li
            key={file.id}
            className='relative rounded-[4px]'
          >
            <div
              style={{
                backgroundImage: `url(${file.preview})`,
              }}
              className={c(
                'h-[84px] min-w-[84px] max-w-[84px] rounded-[4px]',
                'bg-cover bg-center',
              )}
            />
            <button
              className={c('absolute right-[3px] top-[5px]')}
              type='button'
              onClick={() => handleRemovePhoto(file.id)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  d='M2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8Z'
                  fill='black'
                  fill-opacity='0.5'
                />
                <path
                  d='M6 6L7.99996 7.99996M7.99996 7.99996L9.99991 9.99991M7.99996 7.99996L6 9.99991M7.99996 7.99996L9.99991 6'
                  stroke='white'
                />
              </svg>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default UploadImagesContainer;
