import { useRef, useState } from 'react';

import c from '../../../util/c';

type UploadImagesContainerProps = {
  photos: Array<{
    id: string;
    preview: string;
    fileName: string;
  }>;
};

const ImagesContainer = ({ photos }: UploadImagesContainerProps) => {
  const scrollableRef = useRef<HTMLUListElement>(null); // 스크롤 가능한 영역 참조
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState({ left: 0, top: 0 });

  const handleMouseDown = (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    const scrollable = scrollableRef.current;
    if (!scrollable) return;

    setIsDragging(true);
    setStartPosition({
      x: e.pageX - scrollable.offsetLeft,
      y: e.pageY - scrollable.offsetTop,
    });
    setScrollPosition({
      left: scrollable.scrollLeft,
      top: scrollable.scrollTop,
    });
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    const scrollable = scrollableRef.current;
    if (!scrollable || !isDragging) return;

    e.preventDefault();
    const x = e.pageX - scrollable.offsetLeft;
    const y = e.pageY - scrollable.offsetTop;

    const walkX = (x - startPosition.x) * 1; // x축 이동 거리
    const walkY = (y - startPosition.y) * 1; // y축 이동 거리

    scrollable.scrollLeft = scrollPosition.left - walkX;
    scrollable.scrollTop = scrollPosition.top - walkY;
  };

  const handleMouseUpOrLeave = () => setIsDragging(false);
  return (
    <>
      <ul
        ref={scrollableRef}
        className={c(
          'flex items-center justify-start',
          'space-x-[10px]',
          'h-[84px]',
          'scrollbar-hidden overflow-x-auto',
        )}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUpOrLeave}
        onMouseLeave={handleMouseUpOrLeave}
      >
        {photos.map((file) => (
          <li
            key={file.id}
            className='relative rounded-[4px]'
          >
            <div
              style={{
                backgroundImage: `url(${file.preview})`,
              }}
              className={c(
                'h-[84px] min-w-[84px] max-w-[84px] rounded-[4px]',
                'bg-cover bg-center',
              )}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default ImagesContainer;
